import styled from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Content = styled.div`
    flex-grow:1;
    overflow-y:scroll;
`;

export const DataEmpty = styled.div`
    margin-top: 20px;
    text-align: center;
`;