import { Box, Button, InputBase, Paper, Stack } from "@mui/material";
import React, { useState } from "react";

const SendMessageBox = ({ isSending, handleSend }) => {
  const [inputText, setInputText] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSending) return;
    handleSend(inputText);
    setInputText("");
  }

  return (
    <Box sx={{ p: "8px 12px", backgroundColor: "#ffe4c4", position: "fixed", bottom: 0, left: 0, right: 0 }}>
      <Stack component="form" direction={"row"} onSubmit={(e) => handleSubmit(e)}>
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            mr: 1,
            backgroundColor: "#fff",
          }}
        >
          <InputBase
            sx={{ px: 1, flex: 1 }}
            placeholder={""}
            inputProps={{ "aria-label": "input text" }}
            value={inputText || ""}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            autoFocus
            multiline={true}
            rows={3}
          />
        </Paper>

        <Stack direction={"column"} justifyContent={"center"}>
          <Button type="submit" aria-label="send" variant="contained" size="small" disabled={isSending || !inputText}>
            送信
          </Button>
        </Stack>
      </Stack>
    </Box >
  );
};

export default SendMessageBox;
