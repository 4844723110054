import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow: 1;
`

export const Content = styled.div`
    flex-grow:1;
    overflow-y: scroll;
    padding: 0;
`

export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`


export const Info = styled.div`
    padding: 0 5px;
`

export const ButtonDeliveryStaff = styled.button`
    margin-right:0;
    padding: 4px 12px;
    font-size: 1rem;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 5px;
    background-color: var(--morienGreen);
    color: white;
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`;

export const ButtonDelivered = styled.button`
    margin-right:0;
    padding: 4px 12px;
    font-size: 1rem;
    border:1px solid transparent;
    border-radius: 5px;
    background-color: #ba55d3;
    color:white;
    cursor: pointer;
    :active{
        background-color: #9932cc;            
    }
`;