import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { Wapper, Button } from "./styles";
import { useNavigate } from "react-router-dom";

const BackButton = ({ urlBack }) => {
    const navigate = useNavigate();
    return <Wapper>
        <Button onClick={() => { if (!urlBack) navigate(-1); else navigate(urlBack) }}><FontAwesomeIcon icon={faReply} /></Button>
    </Wapper >;
};


export default BackButton;
