import { Box, Stack, Typography } from "@mui/material";
import { ROLE } from "constants";
import React from "react";


const ConversationItem = ({ item }) => {
  const isUser = item.role === ROLE.USER;
  return <Stack direction={'row'} sx={{ backgroundColor: isUser ? "#ececec" : "#fff", padding: 1 }}>
    {/* Avarta */}
    <Box>
      <Box sx={{ height: 30, width: 30, borderRadius: "100%", backgroundColor: isUser ? "gray" : "aqua" }}></Box>
    </Box>
    {/* Message */}
    <Box flex={1} sx={{ pl: 1 }}>
      <Typography sx={{ fontWeight: 700 }}>{isUser ? "User" : "Assistant"}</Typography>
      <Box fontSize={"0.9rem"}><pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{item.content || ""}</pre></Box>
    </Box>
  </Stack>
}

export default ConversationItem;