import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CheckBox, Menu, OverLay, MenuClose, HeaderMenu, UserInfo, BottomMenu, BodyMenu, ContainerInfo, SubUserInfo } from './styles';
import { NavLink, useNavigate } from 'react-router-dom';
import { LocalStorageGetStaff, LocalStorageRemoveToken } from '../../utils';
import loginApi from '../../api/loginApi';
import { ORDER_TYPE } from 'constants';



const NavMenu = ({ idMenu, userName }) => {
    const staff = LocalStorageGetStaff();
    const navigate = useNavigate();

    const handleLogoutClick = async () => {
        try {
            const dtResponse = await loginApi.logout();
            console.log(dtResponse);
            LocalStorageRemoveToken();
            navigate("/login");
        } catch (error) {
            console.log(error);

        }
    }

    return (
        <>
            <CheckBox id={idMenu} hidden />
            <OverLay htmlFor={idMenu} />
            <Menu>
                {/* <Wrapper> */}
                <HeaderMenu>
                    <ContainerInfo>
                        <UserInfo>{staff?.staffName || '-'}</UserInfo>
                        <SubUserInfo>{staff?.baseName || '-'}</SubUserInfo>
                    </ContainerInfo>
                    <MenuClose htmlFor={idMenu} className='button icon'>
                        <FontAwesomeIcon icon={faTimes} />
                    </MenuClose>
                </HeaderMenu>
                <BodyMenu>
                    <li>
                        <NavLink to={'/goods'} className='menu-item' htmlFor={idMenu} >新規注文</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/goods-stock'} className='menu-item' htmlFor={idMenu}>在庫発注</NavLink>
                    </li>

                    <li>
                        <NavLink to={'/client'} className='menu-item' >お客様一覧</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/client-management'} className='menu-item' >お客様（担当のみ）</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/sales-slip'} className='menu-item' >伝票履歴</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/site'} className='menu-item' >現場検索一覧</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/site-new'} className='menu-item' >現場登録</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/action-list'} className='menu-item' >訪問実績一覧</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/action'} className='menu-item' >訪問実績登録</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/company'} className='menu-item' >法人・個人</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/assistant'} className='menu-item' >アシスタント</NavLink>
                    </li>

                </BodyMenu>
                <BottomMenu onClick={handleLogoutClick}>
                    ログアウト <FontAwesomeIcon icon={faRightFromBracket} />
                </BottomMenu>
                {/* </Wrapper> */}
            </Menu>
        </>
    )
}

NavMenu.propTypes = {
    idMenu: PropTypes.string,
    userName: PropTypes.string
}

NavMenu.defaultProps = {
    idMenu: 'id',
    userName: 'スタッフ名前',
}
export default NavMenu;
