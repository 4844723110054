import axiosClient from "./axiosClient";

const salesSlipApi = {

    search(params) {
        const url = '/sales-slip';
        return axiosClient.get(url, { params: params });
    },
    fetch(params) {
        const url = '/sales-slip-details';
        return axiosClient.get(url, { params: params });
    },
    saveSlip(params) {
        const url = '/sales-slip';
        return axiosClient.post(url, params);
    },
    updateDeliveryStaff(params) {
        const url = '/sales-slip/delivery-staff';
        return axiosClient.post(url, params);
    }
}

export default salesSlipApi;
