import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import salesSlipApi from "api/salesSlipApi";
import HeaderBox from "components/HeaderBox";
import Loading from "components/Loading";
import MessageBox from "components/MessageBox";
import { MESSAGE } from "constants";
import GoodsList from "../components/GoodsList";
import { ButtonDeliveryStaff, ButtonDelivered, Content, Info, Line, MLabel, Wrapper } from "./styles";
import BackButton from "components/BackButton";

const SalesSlipDetails = () => {
    const { salesSlipID } = useParams();
    const [salesSlip, setSalesSlip] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    useEffect(() => {
        const fetchSalesSlip = async () => {
            try {
                setIsLoading(true);
                const params = {
                    salesSlipID: salesSlipID
                }
                const response = await salesSlipApi.fetch(params);
                setSalesSlip(response.data?.salesSlip || null);
                setIsLoading(false);

            } catch (error) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [error]
                }
                setMessage(errMsg);
                setIsLoading(false);
            }
        }
        fetchSalesSlip();
    }, [salesSlipID]);


    /**
     * mode: 0:update delivery staff || 1: update delivered = 1
     */
    const handleUpdateDelivery = async (mode) => {
        if (salesSlipID <= 1 || !salesSlip) return;
        try {
            setIsLoading(true);
            const params = {
                salesSlipID: salesSlipID,
                deliveryStaffID: salesSlip.DeliveryStaffID,
                mode: mode
            }
            const response = await salesSlipApi.updateDeliveryStaff(params);
            setSalesSlip(response.data?.salesSlip || null);
            const errMsg = {
                type: MESSAGE.INFO,
                contents: ["配達スタッフを更新しました"]
            }
            setMessage(errMsg);
            setIsLoading(false);

        } catch (error) {
            const errMsg = {
                type: MESSAGE.ERROR,
                contents: [error]
            }
            setMessage(errMsg);
            setIsLoading(false);
        }
    }

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title={salesSlip?.ClientName || '伝票明細'} showCart />
            <MessageBox message={message} setMessage={setMessage} />
            {!!salesSlip &&
                <Content>
                    <Info>
                        <Line>
                            <MLabel>受注番号：</MLabel>{salesSlip.SlipCodeAll}{salesSlip.SlipCodeClient}
                        </Line>
                        <Line>
                            <MLabel>受注日：</MLabel>{(salesSlip.AcceptedDate || '--').substring(0, 10)}
                        </Line>
                        <Line>
                            <MLabel>お届け先：</MLabel>{salesSlip.SiteName || '--'}
                        </Line>
                        <Line>
                            <MLabel>納品スタッフ：</MLabel>{salesSlip.DeliveryStaffName || '--'}
                        </Line>
                        <Line>
                            <MLabel>納品日：</MLabel>{(salesSlip.DeliveryDate || '--').substring(0, 10) || '--'}
                        </Line>
                        <Line>
                            <MLabel>配送タイプ：</MLabel>{salesSlip.DeliveryType || '--'}　{salesSlip.DeliveryTypeComment || '--'}
                        </Line>
                        <Line>
                            <MLabel>配送時間：</MLabel>{salesSlip.DeliveryTime || '--'}
                        </Line>
                        <Line>
                            <MLabel>配送状況：</MLabel>{salesSlip.Delivered > 0 ? '済' : '未'}
                        </Line>
                        <Line>
                            <MLabel>配送スタッフ：</MLabel>{salesSlip.DeliveryStaffName || '--'}
                        </Line>
                        <Line>
                            <MLabel>現場の住所：</MLabel>{salesSlip.SiteAddress || '--'}
                        </Line>
                        <Line>
                            <MLabel>メモ：</MLabel>{salesSlip.SalesSlipMemo || '--'}
                        </Line>
                        {(salesSlip.Delivered < 1) &&
                            (<Line style={{ display: "flex", justifyContent: "space-around", margin: "4px" }}>
                                {(salesSlip.StaffIDGet === salesSlip.DeliveryStaffID) && (<ButtonDelivered onClick={() => { handleUpdateDelivery(1); }}>配達済</ButtonDelivered>)}
                                {(salesSlip.StaffIDGet !== salesSlip.DeliveryStaffID) && (<ButtonDeliveryStaff onClick={() => { handleUpdateDelivery(0); }}   >配達担当</ButtonDeliveryStaff>)}
                            </Line>)}
                    </Info>
                    <GoodsList goodsList={salesSlip.GoodsList} />
                </Content>
            }
            <BackButton urlBack={"/sales-slip"} />
        </Wrapper>
    );
};

export default SalesSlipDetails;
