import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    background-color:beige;
    justify-content: center;    
    align-items:center;
    
`;

export const WrapperForm = styled.form`
    display:flex;
    width: 80%;
    justify-content: center;    
    padding: 5px 0;
    /* input{
        width: 70%;
        max-width: 500px;
        border: 2px solid #c6c6c6;
        border-radius: 5px;
        font-size: 1rem;
        padding: 5px;
        @media screen and (max-width: 768px){
        }
    } */
    
    button{
        margin-left:3px;
        padding: 0 13px;
        font-size: 1rem;
        border:1px solid transparent;
        border-color: var(--morienGreen);
        border-radius: 5px;
        background-color: var(--morienGreen);
        color: white;
        cursor: pointer;
        :active{
            background-color: #32cd32;            
        }
    }

`;

export const InputBox = styled.div`
    width: 70%;
    max-width: 500px;    
    position: relative;  
    display: inline;
    border-radius: 8px;    
    border: none;
    overflow: hidden;
    flex: 1;
`;

export const Input = styled.input`
    padding: 4px 32px 4px 8px;
    width:100%;
    border:none;
    border-radius: 8px;
    outline: none;   
    font-size: var(--fontNormal);
    border: 2px solid #c6c6c6;
`;

export const BtnClear = styled.div`
    position: absolute;
    top:2px;
    right: 8px;
    bottom:0;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    color: #a3a3a3;
    cursor: pointer;
    :hover{
        color:#838383;
        transform:scale(1.2);            
    }
`;