import styled from 'styled-components';
import { Link } from "react-router-dom";


export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
`;

export const Header = styled.div`
    width: 80%;
    margin-top: 60px;    
    text-align: center;
    .logo{
        width: 100%;
        margin: 10px auto;
        max-width: 400px;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    margin-top: 8px;
    padding-bottom: 60px;
    overflow-y: auto;
    scrollbar-width: thin;
`;

export const Button = styled(Link)`
    margin: 10px auto;
    text-decoration: none;
    color: white;
    background-color: var(--morienGreen);
    font-size: 1.5rem;
    line-height: 40px;
    font-weight: bold;
    text-align:center;
    border-radius: 3px;
    min-width: 180px;
    cursor: pointer;

    :active{
        background-color: rgba(0,149,39,0.5);
        color: black;
    }
`