export const TOKEN_KEY = 'st-key';
export const STAFF_KEY = 'st-staff';
export const CART_KEY = 'st-cart';

export const PW_TAIL = '@neir0m';
export const BASE_NAME = '';
export const URL_HOME = '/';
export const URL_LOGIN = '/login';
export const API_BASE_URL = 'https://shanai-order.morien-paint.com/api';
//export const API_BASE_URL = 'http://shanai-order.localhost:8082/api';
export const MEMO_KEY = 'temp-memo';
export const IS_STOCK_KEY = 'is-stock';

export const MESSAGE = {
    INFO: 'info',
    ERROR: 'error',
    WARNING: 'warning',
}

export const DELIVERY_TIME = [
    { id: 1, name: '0指定なし' },
    { id: 4, name: '1朝一番' },
    { id: 2, name: '2午前便' },
    { id: 5, name: '3昼一番' },
    { id: 3, name: '4午後便' },
]

export const DELIVERY_TYPE = [
    { id: 1, name: '未入力' },
    { id: 2, name: '現場届け' },    //SiteTypeID: 1
    { id: 3, name: '店届け' },      //SiteTypeID: 2
    { id: 4, name: '倉庫届け' },    //SiteTypeID: 3
    { id: 5, name: '引取り' },
    // { id: 7, name: '物流' },
    // { id: 8, name: '発送' },
    // { id: 9, name: '直送' },
    // { id: 10, name: '預り' },
    // { id: 20, name: '値引き' },
]

export const BASE_LIST = [
    { id: 1, name: '本社' },
    { id: 101, name: '玉津' },
    { id: 105, name: '姫路' }
]

export const HACCHU_TEN = [
    { id: 1, name: '本社' },
    { id: 21, name: 'CW' },
    { id: 101, name: '玉津' },
    { id: 105, name: '姫路' }
]

export const ORDER_TYPE = {
    CHUMON: 'chumon',
    HACCHU: 'hacchu'
}

export const ROLE = {
    ASSISTANT: 'assistant',
    USER: 'user',
}