import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Auth from './Auth';
import { LocalStorageClearAll } from './utils';
import { URL_HOME } from 'constants';

export const PrivateRoute = () => {
    const isLogin = Auth.isLogin();
    if (!isLogin) {
        LocalStorageClearAll();
    }
    const location = useLocation();
    return isLogin ? <Outlet /> : <Navigate to={`/login?redirect=${location.pathname}${location.search}`} />;
}

export const RestrictedRoute = () => {
    const isLogin = Auth.isLogin();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const urlRedirect = search.get("redirect") || URL_HOME;
    return isLogin ? <Navigate to={urlRedirect} replace={true} /> : <Outlet />;
}