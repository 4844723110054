import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BtnClear, Input, InputBox, Wrapper, WrapperForm } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({ textHolder, termSearch, setTermSearch }) => {

    const [txtSearch, setTxtSearch] = useState(termSearch?.txtSearch || '');

    function handleSubmit(event) {
        event.preventDefault();
        setTermSearch({ ...termSearch, txtSearch: txtSearch });
    }
    const handleClear = () => {
        setTxtSearch("");
        setTermSearch((preValue) => ({
            ...preValue,
            txtSearch: "",
        }))
    }

    return (
        <Wrapper>
            <WrapperForm onSubmit={handleSubmit} >
                <InputBox>
                    <Input
                        type='text'
                        name='txtSearch'
                        placeholder={textHolder}
                        maxLength={100}
                        value={txtSearch}
                        onChange={(e) => { setTxtSearch(e.target.value); }}
                    />
                    {!!txtSearch && (
                        <BtnClear onClick={() => { handleClear(); }}><FontAwesomeIcon icon={faCircleXmark} /></BtnClear>
                    )}
                </InputBox>
                <button type='submit'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
            </WrapperForm>
        </Wrapper>
    )
}

SearchBox.propTypes = {
    textHolder: PropTypes.string,
    termSearch: PropTypes.object.isRequired,
    setTermSearch: PropTypes.func.isRequired,
}

SearchBox.defaultProps = {
    textHolder: 'Search...',
}

export default SearchBox;