import { Box } from "@mui/material";
import asisstantApi from "api/assistantApi";
import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "./styles";
import HeaderBox from "components/HeaderBox";
import MessageBox from "components/MessageBox";
import SendMessageBox from "./SendMessageBox";
import ConversationItem from "./ConversationItem";
import { MESSAGE } from "constants";
import { ROLE } from "constants";

const Assistant = () => {
  const [isSending, setIsSending] = useState(false);

  const [message, setMessage] = useState({
    title: '',
    type: '',
    contents: []
  });
  const messagesEndRef = useRef(null);

  const [conversation, setConversation] = useState([
    { role: ROLE.ASSISTANT, content: "こんにちは！" }
  ]);

  // Effect to scroll to the bottom of chat messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [conversation]);

  const handleSend = async (inputText) => {
    if (!inputText) return;
    try {
      setIsSending(true);
      const params = {
        text: inputText
      }
      setConversation(preVal => [...preVal, { role: ROLE.USER, content: inputText || '' }]);
      const { data } = await asisstantApi.sendQuestion(params);
      setConversation(preVal => [...preVal, { role: ROLE.ASSISTANT, content: data?.content || 'すみません、依頼がわかりません。' }]);
      setIsSending(false);
    } catch (err) {
      console.log(err);
      const errMsg = {
        type: MESSAGE.ERROR,
        contents: [err]
      }
      setMessage(errMsg);
      setIsSending(false);
    }
  }
  return (
    <Wrapper>
      <HeaderBox title='アシスタント' showCart />
      <MessageBox message={message} setMessage={setMessage} />
      <Box sx={{ height: "100%", overflowY: "scroll", paddingBottom: 16 }} ref={messagesEndRef}>
        {conversation.map((c, index) => (<ConversationItem key={index} item={c} />))}
        <div ref={messagesEndRef}></div>
      </Box>
      <SendMessageBox handleSend={handleSend} isSending={isSending} />
    </Wrapper>
  );
};

export default Assistant;
